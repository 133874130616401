import YoungEventImage from '@/assets/images/news/young-event.jpg'

const NewsList = [
  {
    key:"NEWS_GROUP_1",
    title: '年輕族群心理健康支持方案',
    content: '衛生福利部於113年8月1日至114年12月31日，提供 15歲到45歲有心理諮商需求的年輕朋友，每人3次免費心理諮商。本所為此方案合作之心理諮商機構，歡迎年齡符合之民眾預約使用。',
    img: YoungEventImage,
    bottomList: [
      {
        key: 'NEWS_GROUP_1_BOTTOM_1',
        content: '【預約表單】請於備註欄填寫『使用青壯世代心理健康支持方案』',
        link: 'https://www.accmentalhealth.com/appointment'
      },
      {
        key: 'NEWS_GROUP_1_BOTTOM_2',
        content: '【年輕族群心理健康支持方案】焦點新聞',
        link: 'https://www.mohw.gov.tw/cp-16-79408-1.html'
      },
      {
        key: 'NEWS_GROUP_1_BOTTOM_3',
        content: '【年輕族群心理健康支持方案】常見問答',
        link: 'http://218.32.46.148/'
      }
    ]
  },
]

export { NewsList }
