<template>
  <div class="page-container">
    <h2 v-if="$slots.title" class="title">
      <slot name="title" />
    </h2>
    <div class="content" :style="{ 'text-align': contentTextAlign }">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageContainer',
  props: {
    contentTextAlign: {
      type: String,
      default: 'start'
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  box-sizing: border-box;
  max-width: $content-width;
  margin: 0 auto;

  @include ipad {
    padding: 60px 16px;
  }

  .title {
    width: 100%;
    padding: 32px;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 2px;
    text-align: center;
    box-sizing: border-box;
    transform: translateY(-20px);
    opacity: 0;
    animation: fadeIn 0.8s ease-in-out forwards;

    @keyframes fadeIn {
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .content {
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 1px;
    opacity: 0;

    animation: fadeIn 1.2s ease-in-out 0.5s forwards;

    @keyframes fadeIn {
      100% {
        opacity: 1;
      }
    }
  }
}
</style>
