<template>
  <div>
    <Banner class="home__banner" />
    <PageContainer class="home">
      <template #content>
        <div class="home__content">
          <div class="about">
            <Section :imageSrc="AboutImage1">
              <template #title>關於伴</template>
              <template #content>
                面對生命中的難題，也許我們都曾經歷不安恐懼，
                <br />
                也曾陷入難解的混亂與矛盾，
                <br />
                或埋首隱藏著內心的脆弱與受傷，
                <br />
                在孤寂的黑暗中躊躇卻步，看不見光亮。
                <br />
                <br />
                兩位心理師，幸運地在人生路途中相遇，
                <br />
                一路同行，彼此相依。
                <br />
                <br />
                陪伴，
                <br />
                為我們打破了孤立無援的恐懼，
                <br />
                為我們帶來暖心的療癒，
                <br />
                使我們看見自己的盲點與課題，
                <br />
                讓我們在平凡又脆弱的靈魂中，重拾自信與力量！
                <br />
                <br />
                若你正在黑暗的路上無所依靠，難以繼續前行，
                <br />
                請讓我們用陪伴的力量，與你一同看見希望！
                <br />
              </template>
            </Section>
          </div>
        </div>
      </template>
    </PageContainer>
  </div>
</template>

<script>
import PageContainer from '@/components/common/PageContainer.vue'
import Banner from '@/components/home/Banner'
import Section from '@/components/common/section/Section.vue'
import AboutImage1 from '@/assets/images/homeAbout-1.jpg'

const META_TITLE = '伴心理諮商所'
const META_DESCRIPTION =
  '陪伴，為我們打破了孤立無援的恐懼，為我們帶來暖心的療癒，使我們看間自己的盲點與課題，讓我們在平凡又脆弱的靈魂中，重拾自信與力量！'

export default {
  name: 'Home',
  components: {
    PageContainer,
    Banner,
    Section
  },
  metaInfo() {
    return {
      title: META_TITLE,
      description: META_DESCRIPTION,
      meta: [
        // 如果母畫面和子畫面都有 meta info，則兩個 description 都會併存，可以利用 vmid 去解決這個問題
        // 同樣的 vmid 子畫面會取代母畫面
        {
          vmid: 'description',
          name: META_TITLE,
          content: META_DESCRIPTION
        },
        { property: 'og:title', content: META_TITLE },
        { property: 'og:site_name', content: META_TITLE },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: META_DESCRIPTION
        }
      ]
    }
  },
  data() {
    return {
      AboutImage1
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  .home__banner {
    margin: 0 auto;
  }

  .home__content {
    padding: 16px 0;
  }

  .about {
    display: flex;
    justify-content: center;
  }
}
</style>
