<template>
  <div class="dropdown">
    <slot name="prefix" />
    <div class="dropdown__list">
      <div
        v-for="(item, index) in list"
        :key="`item_${index}`"
        class="list__item"
        @click="onItem(item)"
      >
        {{ item.text }}
      </div>
    </div>
    <slot name="subfix" />
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    list: {
      type: Array,
      default: () => [
        // item : {
        //   text: 'article',
        //   routerParam: {
        //     name: 'Article'
        //   }
      ]
    }
  },
  methods: {
    onItem({ routerParam }) {
      this.$router.push(routerParam)
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  background-color: $light-beige;
  border-radius: 5px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);

  .list__item {
    padding: 12px 20px;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: $light-beige;
    }
  }
}
</style>
