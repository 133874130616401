<template>
  <div class="external-item">
    <p class="title">{{ data.content }}</p>
    <a class="link" :href="data.link" target="_blank" rel="noopener nofollow">{{ data.link }}</a>
  </div>
</template>

<script>
export default {
  name: 'ExternalItem',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.external-item {
  .title {
    font-size: 16px;
  }

  .link {
    word-break: break-all;
  }
}
</style>