<template>
  <div class="process-list">
    <div v-for="(item, index) in list" :key="item.title" class="process__item">
      <ProcessItem
        :icon="item.icon"
        :title="item.title"
        :contents="item.contents"
      />
      <IconArrowDown
        class="arrow-icon"
        :class="{ 'is-last': list.length - 1 === index }"
      />
    </div>
  </div>
</template>

<script>
import ProcessItem from './ProcessItem.vue'
import IconArrowDown from '@/components/icons/IconArrowDown.vue'
export default {
  name: 'ProcessList',
  components: {
    ProcessItem,
    IconArrowDown
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.process-list {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include ipad {
    flex-direction: column;
    align-items: center;
  }

  .process__item {
    margin-top: 20px;
    display: flex;
    align-items: center;

    @include ipad {
      flex-direction: column;
      align-items: center;
      margin-top: 0;

      &:first-child {
        margin-top: 20px;
      }
    }
  }

  .arrow-icon {
    width: 50px;
    height: 50px;
    transform: rotate(-90deg);

    &.is-last {
      display: none;
    }

    @include lg-desktop {
      margin: 0;
    }

    @include ipad {
      transform: rotate(0);
    }
  }
}
</style>
