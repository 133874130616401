<template>
  <PageContainer class="process">
    <template #title>流程與收費</template>
    <template #content>
      <div class="process__content">
        <p class="quote">
          <span>
            本諮商所採取
            <span class="quote__point">「線上/電話預約制」</span>
            ，沒有提供現場預約服務喔！
          </span>
        </p>
        <ProcessList class="process__list" :list="SOP" />
      </div>
      <Fee />
    </template>
  </PageContainer>
</template>

<script>
import PageContainer from '@/components/common/PageContainer.vue'
import ProcessList from '@/components/service/process/ProcessList.vue'
import Fee from '@/views/service/Fee.vue'

import { SOP } from './processConfig.js'

const META_TITLE = '伴心理諮商所 - 流程與收費'
const META_DESCRIPTION =
  '本諮商所採取 「線上/電話預約制」 ，沒有提供現場預約服務喔！'

export default {
  name: 'Process',
  components: {
    PageContainer,
    ProcessList,
    Fee
  },
  metaInfo() {
    return {
      title: META_TITLE,
      meta: [
        // 如果母畫面和子畫面都有 meta info，則兩個 description 都會併存，可以利用 vmid 去解決這個問題
        // 同樣的 vmid 子畫面會取代母畫面
        { vmid: 'description', name: 'description', content: META_DESCRIPTION },
        { property: 'og:title', content: META_TITLE },
        { property: 'og:site_name', content: META_TITLE },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: META_DESCRIPTION
        }
      ]
    }
  },
  data() {
    return {
      SOP
    }
  }
}
</script>

<style lang="scss" scoped>
.process {
  .process__content {
    margin: 0 auto;
    padding: 0 24px 36px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .quote {
    font-size: 16px;
  }

  .quote__point {
    color: $light-red;
  }
}
</style>
