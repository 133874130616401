import YuanRuImage from '@/assets/images/members/yuan-ru.jpg'
import YiPingImage from '@/assets/images/members/yi-ping.jpg'
import JunKaiImage from '@/assets/images/members/jun-kai.jpg'

const MemberList = [
  {
    name: '蔡宛儒',
    job: '諮商心理師',
    img: YuanRuImage,
    introList: [
      {
        title: '證照',
        list: ['諮商心理師證照(諮心字第003301號)', '日本和諧粉彩準指導師證照']
      },
      {
        title: '學歷',
        list: ['國立彰化師範大學輔導與諮商研究所碩士']
      },
      {
        title: '經歷',
        list: [
          '立心理諮商所諮商心理師',
          '慈濟大學諮商中心專任心理師',
          '新北市立福營國民中學代理專任輔導教師'
        ]
      },
      {
        title: '關注',
        list: ['情緒壓力調適、人際互動關係、兒童青少年議題']
      }
    ]
  },
  {
    name: '林宜蘋',
    job: '諮商心理師',
    img: YiPingImage,
    introList: [
      {
        title: '證照',
        list: ['諮商心理師證照(諮心字第003242號)']
      },
      {
        title: '專業訓練',
        list: ['心理劇訓練時數500小時以上']
      },
      {
        title: '學歷',
        list: ['台北市立大學心理與諮商輔導學系研究所碩士']
      },
      {
        title: '經歷',
        list: [
          '立心理諮商所諮商心理師',
          '聖約翰科技大學諮商心理師',
          '台北市立明倫高級中學輔導教師',
          '台北市生命線安心專線線上督導',
          '台北市生命線安心專線接線輔導員'
        ]
      },
      {
        title: '關注',
        list: [
          '情緒困擾、人際關係困擾、親密關係困擾、家庭議題、自殺關懷與防治、心理劇治療'
        ]
      }
    ]
  },
  {
    name: '曹鈞凱',
    job: '諮商心理師',
    img: JunKaiImage,
    introList: [
      {
        title: '證照',
        list: ['諮商心理師證照(諮心字第003356號)']
      },
      {
        title: '專業訓練',
        list: ['心理劇訓練時數450小時以上', '敘事治療訓練時數 300小時以上']
      },
      {
        title: '學歷',
        list: ['台北市立大學心理與諮商輔導學系研究所碩士']
      },
      {
        title: '經歷',
        list: [
          '萬里國小、野柳國小、大鵬國小專任輔導教師',
          '慈濟科技大學專任心理師',
          '張老師基金會台北中心兼任心理師',
          '戒菸專線專任諮詢員'
        ]
      },
      {
        title: '關注',
        list: [
          '存在主義、生命意義探索、喪親與失落議題、親密關係議題、藝術治療、正念減壓、非暴力溝通、完形治療、人際無結構團體、心理劇'
        ]
      }
    ]
  }
]

export { MemberList }
