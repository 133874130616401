<template>
  <div class="section" :style="style">
    <div class="section__content">
      <h2 class="title">
        <slot name="title" />
      </h2>
      <div class="content">
        <div class="content__text"><slot name="content" /></div>
        <img v-if="imageSrc" class="content__image" :src="imageSrc" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section',
  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    backgroundImage: {
      type: String,
      default: ''
    }
  },
  computed: {
    style() {
      return {
        'background-image': this.backgroundImage
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  padding: 80px 12px 120px;
  box-sizing: border-box;

  @include ipad {
    padding: 60px 16px;
  }

  .section__content {
    margin: 0 auto;
    width: fit-content;
    max-width: $content-width;
    text-align: start;

    @include ipad {
      width: 100%;
      text-align: center;
    }
  }

  .title {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 2px;
  }

  .content {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 1px;
    white-space: pre-line;
    display: flex;
    align-items: center;

    @include ipad {
      flex-direction: column;
    }
  }

  .content__image {
    margin-left: 32px;
    max-width: 460px;
    height: fit-content;

    @include ipad {
      margin: 16px 0 0 0;
      width: 100%;
      max-width: auto;
    }
  }
}
</style>
