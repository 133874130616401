<template>
  <PageContainer class="news">
    <template #title>最新消息</template>
    <template #content>
      <div class="news__content">
        <NewsItem v-for="newsItem in NewsList" :key="newsItem.key" :data="newsItem"/>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import PageContainer from '@/components/common/PageContainer.vue'
import NewsItem from '@/components/news/NewsItem.vue'

import { NewsList } from '@/views/newsConfig.js'

const META_TITLE = '伴心理諮商所 - 最新消息'
const META_DESCRIPTION = ''

export default {
  name: 'Members',
  components: {
    PageContainer,
    NewsItem
  },
  metaInfo() {
    return {
      title: META_TITLE,
      meta: [
        // 如果母畫面和子畫面都有 meta info，則兩個 description 都會併存，可以利用 vmid 去解決這個問題
        // 同樣的 vmid 子畫面會取代母畫面
        { vmid: 'description', name: 'description', content: META_DESCRIPTION },
        { property: 'og:title', content: META_TITLE },
        { property: 'og:site_name', content: META_TITLE },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: META_DESCRIPTION
        }
      ]
    }
  },
  data() {
    return {
      NewsList
    }
  }
}
</script>

<style lang="scss" scoped>
.news {
  .news__content {
    margin: 0 auto;
    padding: 0 24px 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
