<template>
  <div class="main">
    <Header class="header" />
    <router-view class="content" />
    <Footer class="footer" />
  </div>
</template>

<script>
import Header from '@/components/header/Header.vue'
import Footer from '@/components/footer/Footer.vue'

export default {
  name: 'Main',
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: $darkest-grey;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .header {
    position: fixed;
    z-index: 9;
  }

  .content {
    margin-top: $header-height; // header height
    flex-grow: 1;
  }
}
</style>
