<template>
  <div class="header-dropdown">
    <div class="header-dropdown__button" @click="onTitle">
      <span class="title" :class="titleColor">{{ buttonText }}</span>
      <IconArrowDown v-if="isDropdown" class="arrow-icon" />
    </div>
    <Dropdown
      class="header-dropdown__list"
      :class="[listSideAlign]"
      :list="list"
    />
  </div>
</template>

<script>
import IconArrowDown from '@/components/icons/IconArrowDown.vue'
import Dropdown from '@/components/common/dropdown/Dropdown.vue'

export const LIST_SIDE_ALIGN = {
  LEFT: 'left',
  RIGHT: 'right'
}

export default {
  name: 'HeaderDropdown',
  components: {
    IconArrowDown,
    Dropdown
  },
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    titleColor: {
      type: String,
      default: ''
    },
    routerParam: {
      type: Object,
      default: null
    },
    list: {
      type: Array,
      default: () => []
    },
    listSideAlign: {
      type: String,
      default: LIST_SIDE_ALIGN.LEFT
    }
  },
  computed: {
    isDropdown() {
      return this.list.length > 0
    }
  },
  methods: {
    onTitle() {
      if (!this.routerParam) {
        return
      }
      this.$router.push(this.routerParam)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-dropdown {
  position: relative;

  &:hover > .header-dropdown__list {
    display: block;
  }

  .title {
    &.brown {
      color: $brown;

      &:hover {
        color: $light-brown;
      }
    }
  }

  .header-dropdown__button {
    padding: 5px 6px;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      color: $black;

      .arrow-icon {
        transform: rotate(180deg);
      }
    }
  }

  .arrow-icon {
    transition: transform 0.2s;
  }

  .header-dropdown__list {
    position: absolute;
    display: none;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}
</style>
