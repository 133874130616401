<template>
  <div class="process-item">
    <component :is="icon" />
    <p class="title">{{ title }}</p>
    <div class="content">
      <p v-for="(text, index) in contents" :key="`item__${index}`">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcessItem',
  props: {
    icon: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    contents: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.process-item {
  padding: 24px 0 0;
  width: 273px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $dark-beige;

  @include lg-desktop {
    width: 180px;
  }

  @include desktop {
    width: 160px;
  }

  @include ipad {
    width: 250px;
  }

  .title {
    padding: 8px 0;
    font-size: 20px;
    font-weight: 700;
  }

  .content {
    padding: 0 16px 0;
    box-sizing: border-box;
    width: 100%;
    margin-top: 12px;
    text-align: center;
    background-color: $beige;
  }
}
</style>
