const HEADER_LIST = [
  {
    title: '關於伴',
    routerParam: {
      name: 'home'
    }
  },
  {
    title: '最新消息',
    routerParam: {
      name: 'news'
    }
  },
  {
    title: '諮商服務',
    list: [
      {
        text: '關於諮商',
        routerParam: {
          name: 'aboutCounseling'
        }
      },
      {
        text: '流程與收費',
        routerParam: {
          name: 'process'
        }
      }
    ]
  },
  {
    title: '團隊成員',
    routerParam: {
      name: 'members'
    }
  },
  {
    title: '諮商空間',
    routerParam: {
      name: 'space'
    }
  },
  {
    title: '交通方式',
    routerParam: {
      name: 'transportation'
    }
  },
  {
    title: '立即預約',
    titleColor: 'brown',
    list: [
      {
        text: '一般預約',
        routerParam: {
          name: 'appointment'
        }
      },
      {
        text: '教師方案預約',
        routerParam: {
          name: 'teacherAppointment'
        }
      }
    ]
  }
  // {
  //   title: 'common.rentSpace',
  //   routerParam: {
  //     name: 'rentSpace'
  //   }
  // }
]

const LANGUAGE_LIST = {
  title: 'header.language',
  list: [
    {
      text: '繁體中文',
      routerParam: {
        name: 'test'
      }
    },
    {
      text: 'English',
      routerParam: {
        name: 'test2'
      }
    }
  ]
}

export { HEADER_LIST, LANGUAGE_LIST }
