<template>
  <PageContainer class="members">
    <template #title>團隊成員</template>
    <template #content>
      <div class="members__content">
        <MemberItem
          v-for="item in MemberList"
          :key="item.name"
          class="member-item"
          :data="item"
        />
      </div>
    </template>
  </PageContainer>
</template>

<script>
import PageContainer from '@/components/common/PageContainer.vue'
import MemberItem from '@/components/member/MemberItem.vue'
import { MemberList } from './membersConfig.js'

const META_TITLE = '伴心理諮商所 - 團隊成員'
const META_DESCRIPTION = MemberList.map(({ name, job }) => {
  return `${name} ${job}`
})

export default {
  name: 'Members',
  components: {
    PageContainer,
    MemberItem
  },
  metaInfo() {
    return {
      title: META_TITLE,
      meta: [
        // 如果母畫面和子畫面都有 meta info，則兩個 description 都會併存，可以利用 vmid 去解決這個問題
        // 同樣的 vmid 子畫面會取代母畫面
        { vmid: 'description', name: 'description', content: META_DESCRIPTION },
        { property: 'og:title', content: META_TITLE },
        { property: 'og:site_name', content: META_TITLE },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: META_DESCRIPTION
        }
      ]
    }
  },
  data() {
    return {
      MemberList
    }
  }
}
</script>

<style lang="scss" scoped>
.members {
  .members__content {
    margin: 0 auto;
    padding: 0 24px 36px;
  }

  .member-item {
    margin: 100px auto 0;
    max-width: 800px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
</style>
