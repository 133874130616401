<template>
  <div class="member-item">
    <p class="name-wrap">
      {{ data.name }}
      <span class="job">{{ data.job }}</span>
    </p>
    <div class="info-wrap">
      <img class="photo" :src="data.img" />
      <div class="introduction">
        <div
          v-for="(intro, index) in data.introList"
          :key="`intro_${index}`"
          class="intro-wrap"
        >
          <p class="intro-title">{{ intro.title }}</p>
          <ul class="intro-list">
            <li
              v-for="(text, inIndex) in intro.list"
              :key="`intro_${index}_${inIndex}`"
            >
              {{ text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberItem',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.member-item {
  text-align: start;

  .name-wrap {
    padding: 12px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 6px;

    @include ipad {
      text-align: center;
    }
  }

  .job {
    font-size: 16px;
  }

  .info-wrap {
    display: flex;
    justify-content: center;

    @include ipad {
      flex-direction: column;
      align-items: center;
    }
  }

  .photo {
    width: 300px;
    height: fit-content;
  }

  .introduction {
    flex: 1;

    > * + * {
      margin-top: 28px;
    }

    @include ipad {
      margin-top: 26px;
    }
  }

  .intro-wrap {
    margin-left: 40px;
  }

  .intro-title {
    margin-bottom: 10px;
    padding-bottom: 6px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px dashed $light-grey;
  }

  .intro-list {
    padding-left: 20px;
    list-style-type: disc;
    letter-spacing: 1.5px;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5;
  }
}
</style>
