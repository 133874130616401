<template>
  <PageContainer class="transportation">
    <template #title>交通方式</template>
    <template #content>
      <div class="transportation__content">
        <div class="text__content">
          <div>
            <p class="content__title">諮商所位置</p>
            <p>宜蘭縣礁溪鄉仁愛路11巷5號</p>
            <p>（近礁溪湯圍溝公園）</p>
          </div>
          <div>
            <p class="content__title">交通方式</p>
            <ul class="style--circle">
              <li>
                開車前往：諮商所門口可停機車及一輛汽車，若已無汽車位，請於附近停車場自行停車
              </li>
              <li>
                搭火車：於礁溪火車站下車後，延溫泉路前進至礁溪路五段左轉，至德陽路口右轉，至仁愛路口左轉後可見11巷指示牌左轉，即可至諮商所，總步行時間約8分鐘
              </li>
            </ul>
          </div>
        </div>
        <iframe
          class="googleMap"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9090.537301986447!2d121.77263143241909!3d24.826902889129705!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3467fbc62966e9b5%3A0x33caef01b55bdd1e!2z5Ly05b-D55CG6Kuu5ZWG5omA!5e0!3m2!1szh-TW!2stw!4v1654053465805!5m2!1szh-TW!2stw"
          style="border: 0"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import PageContainer from '@/components/common/PageContainer.vue'

const META_TITLE = '伴心理諮商所 - 交通方式'
const META_DESCRIPTION = '宜蘭縣礁溪鄉仁愛路11巷5號（近礁溪湯圍溝公園）'

export default {
  name: 'Transportation',
  components: {
    PageContainer
  },
  metaInfo() {
    return {
      title: META_TITLE,
      meta: [
        // 如果母畫面和子畫面都有 meta info，則兩個 description 都會併存，可以利用 vmid 去解決這個問題
        // 同樣的 vmid 子畫面會取代母畫面
        { vmid: 'description', name: 'description', content: META_DESCRIPTION },
        { property: 'og:title', content: META_TITLE },
        { property: 'og:site_name', content: META_TITLE },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: META_DESCRIPTION
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.transportation {
  .transportation__content {
    margin: 0 auto;
    padding: 0 24px 36px;

    display: flex;
    justify-content: space-between;
    gap: 36px;

    @include ipad {
      flex-direction: column;
    }
  }

  .text__content {
    flex: 0.4;
    > * + * {
      margin-top: 56px;
    }
  }

  .content__title {
    font-size: 18px;
    font-weight: 600;
  }

  .style--circle {
    padding-left: 20px;
    list-style-type: decimal;
  }

  .googleMap {
    flex: 1;

    @include ipad {
      margin: 0 auto;
      width: 100%;
    }
  }
}
</style>
