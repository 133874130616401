<template>
  <div class="footer">
    <div class="footer__list" v-for="item in FOOTER_LIST" :key="item.key">
      <p class="list__title">{{ item.title }}</p>
      <div
        class="list__item"
        v-for="contentItem in item.list"
        :key="contentItem.key"
      >
        <span>
          {{ contentItem.title }}
          <a
            v-if="contentItem.link"
            class="item__link"
            :href="contentItem.link"
            target="_blank"
            rel="noopener nofollow"
          >
            {{ getContent(contentItem.content) }}
          </a>
          <span v-else class="item__content" :class="contentItem.contentColor">
            {{ getContent(contentItem.content) }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { FOOTER_LIST } from './footerConfig'
import { isI18nMessageExisted } from '@/plugins/i18n'

export default {
  name: 'Footer',
  data() {
    return {
      FOOTER_LIST
    }
  },
  methods: {
    getContent(content) {
      if (isI18nMessageExisted(content)) {
        return this.$t(content)
      }

      return content
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
  background: $lighter-grey;
  letter-spacing: 2px;

  > * + * {
    margin-left: 60px;
  }

  @include ipad {
    flex-direction: column;

    > * + * {
      margin: 10px 0 0 0;
    }
  }

  .list__title {
    height: 18px;
    font-size: 18px;
    font-weight: 700;
  }

  .footer__list {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.5px;

    > * + * {
      margin-top: 12px;
    }

    @include ipad {
      text-align: center;
    }
  }

  .item__content {
    &.brown {
      color: $brown;
    }
  }

  .item__link {
    text-decoration: none;
    color: $black;

    &:hover {
      color: $grey;
    }
  }
}
</style>
