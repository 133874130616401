import IconWriteForm from '@/components/icons/IconWriteForm.vue'
import IconPhoneCall from '@/components/icons/IconPhoneCall.vue'
import IconFly from '@/components/icons/IconFly.vue'
import IconWomenTalking from '@/components/icons/IconWomenTalking.vue'

const SOP = [
  {
    icon: IconWriteForm,
    title: '填寫表單',
    contents: ['線上填寫預約表單', '初步了解心理議題']
  },
  {
    icon: IconPhoneCall,
    title: '專人聯繫',
    contents: ['專人電話聯繫', '了解諮商需求']
  },
  {
    icon: IconFly,
    title: '確認預約',
    contents: ['安排合適心理師', '通知首次會談時間']
  },
  {
    icon: IconWomenTalking,
    title: '開始晤談',
    contents: ['由專業心理師', '進行諮商服務']
  }
]

export { SOP }
