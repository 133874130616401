<template>
  <div class="header" :class="{ shadow: hasShadow }" ref="header">
    <HeaderLogo />
    <HeaderNavigation />
    <!-- <HeaderTools /> -->
  </div>
</template>

<script>
import HeaderLogo from './HeaderLogo'
import HeaderNavigation from './HeaderNavigation.vue'
// import HeaderTools from './HeaderTools.vue'

export default {
  name: 'Header',
  components: {
    HeaderLogo,
    HeaderNavigation
    // HeaderTools
  },
  data() {
    return {
      hasShadow: false
    }
  },
  methods: {
    addEvent() {
      window.addEventListener('scroll', this.handleScroll, 1000, true)
    },
    removeEvent() {
      window.removeEventListener('scroll', this.handleScroll, true)
    },
    handleScroll() {
      const { y } = document.body.getBoundingClientRect()
      if (y === 0) {
        this.hasShadow = false
        return
      }

      this.hasShadow = true
    }
  },
  mounted() {
    this.addEvent()
  },
  beforeDestroy() {
    this.removeEvent()
  }
}
</script>

<style lang="scss" scoped>
.header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $header-height;
  padding: 0 5%;
  background: $white;

  &.shadow {
    box-shadow: 0px 0px 20px $light-grey;
  }

  @include desktop {
    padding: 0 12px;
  }
}
</style>
