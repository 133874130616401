<template>
  <PageContainer class="about-counseling">
    <template #title>關於諮商</template>
    <template #content>
      <div class="about-counseling__content">
        <div class="section">
          <p class="question__title">Q : 什麼是心理諮商？</p>
          <ul>
            <li>
              對於心理諮商還不熟悉時，容易會連結到「有病的人才需要」，但其實心理諮商是人人都可以使用的服務喔！每個人在人生不同的階段都會面臨到不同的變化與挑戰，過程中可能會產生困惑或遭遇挫折，此時心理諮商就會是個提供協助的方法。
            </li>
            <li>
              心理諮商會在一個安全、保密的空間裡進行，諮商心理師將會與你一同有方向性及目的性的探索、整理自身關切的議題，找到更好的策略去因應難關。
            </li>
          </ul>
        </div>
        <div class="section">
          <p class="question__title">Q : 什麼狀況需要心理諮商？</p>
          <ul class="ul-style--circle">
            <li>情緒議題（壓力管理、失落創傷、自傷自殺）</li>
            <li>關係議題（親密關係相處、家庭互動、親子教養、人際困擾）</li>
            <li>性別議題（多元性別、性別認同）</li>
            <li>生涯議題（生涯探索與規劃、職場困境）</li>
            <li>自我認識（自我探索、自我成長）</li>
            如果不太確定適不適合，歡迎來訊或來電諮商所，我們可進一步討論喔！
          </ul>
          <img class="decorate-bg" :src="TreeImage" />
        </div>
        <div class="section">
          <p class="question__title">Q : 心理諮商要進行幾次才有效？</p>
          <ul>
            <li>
              通常一階段的心理諮商次數為八次左右，不過得視每個人議題的狀況、以及期望調整、改善的程度而定，在諮商的過程中都可與諮商心理師進行討論，訂定一個合適的目標。
            </li>
          </ul>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import PageContainer from '@/components/common/PageContainer.vue'
import TreeImage from '@/assets/images/decorate/tree-1.png'

const META_TITLE = '伴心理諮商所 - 關於諮商'
const META_DESCRIPTION =
  '對於心理諮商還不熟悉時，容易會連結到「有病的人才需要」，但其實心理諮商是人人都可以使用的服務喔！每個人在人生不同的階段都會面臨到不同的變化與挑戰，過程中可能會產生困惑或遭遇挫折，此時心理諮商就會是個提供協助的方法。'

export default {
  name: 'AboutCounseling',
  components: {
    PageContainer
  },
  metaInfo() {
    return {
      title: META_TITLE,
      meta: [
        // 如果母畫面和子畫面都有 meta info，則兩個 description 都會併存，可以利用 vmid 去解決這個問題
        // 同樣的 vmid 子畫面會取代母畫面
        { vmid: 'description', name: 'description', content: META_DESCRIPTION },
        { property: 'og:title', content: META_TITLE },
        { property: 'og:site_name', content: META_TITLE },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: META_DESCRIPTION
        }
      ]
    }
  },
  data() {
    return {
      TreeImage
    }
  },
  methods: {
    goAppointment() {
      this.$router.push({
        name: 'appointment'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.about-counseling {
  .about-counseling__content {
    margin: 0 auto;
    padding: 0 24px 36px;

    > * + * {
      margin-top: 48px;
    }
  }

  .section {
    position: relative;
    padding: 20px;

    &:nth-child(odd) {
      background: $light-beige;
    }

    &:nth-child(even) {
      background: $skin;
    }
  }

  .question__title {
    font-size: 18px;
    font-weight: 600;
  }

  .ul-style--circle {
    padding-left: 20px;
    list-style-type: circle;
  }

  .link {
    color: $blue;
    cursor: pointer;
  }

  .decorate-bg {
    width: 80px;
    position: absolute;
    bottom: -30px;
    right: -10px;
  }
}
</style>
