<template>
  <div v-if="!isIpad" class="header-navigation">
    <DecorateWrapper v-for="item in HEADER_LIST" :key="item.title">
      <HeaderDropdown
        :buttonText="item.title"
        :titleColor="item.titleColor"
        :routerParam="item.routerParam"
        :list="item.list"
      />
    </DecorateWrapper>
  </div>
  <div v-else class="header-navigation">
    <IconMenu class="header-navigation__menu-icon" @click.native="onMenu" />
    <SideMenu :isShow.sync="isMenuOpen" :list="HEADER_LIST" />
  </div>
</template>

<script>
import HeaderDropdown from './HeaderDropdown'
import SideMenu from '@/components/common/SideMenu'
import IconMenu from '@/components/icons/IconMenu'
import DecorateWrapper from '@/components/common/decorate/DecorateWrapper.vue'
import { HEADER_LIST } from './headerConfig'

export default {
  name: 'HeaderNavigation',
  components: {
    HeaderDropdown,
    SideMenu,
    IconMenu,
    DecorateWrapper
  },
  data() {
    return {
      HEADER_LIST,

      isMenuOpen: false
    }
  },
  methods: {
    onMenu() {
      this.isMenuOpen = !this.isMenuOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.header-navigation {
  display: flex;
  font-weight: bold;

  > * + * {
    margin-left: 32px;
  }

  @include desktop {
    > * + * {
      margin-left: 8px;
    }
  }

  @include ipad {
    > * + * {
      margin-left: 0;
    }
  }

  .header-navigation__menu-icon {
    cursor: pointer;
  }
}
</style>
