<template>
  <PageContainer class="appointment">
    <template #title>教師方案預約</template>
    <template #content>
      <div class="appointment__content">
        <Loading v-if="isQuestionnaireLoading" class="loading" />
        <iframe
          v-show="!isQuestionnaireLoading"
          class="questionnaire"
          ref="questionnaire"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdK0yRmBF_OXCSju1j-TPh2kgFQXGUyWWUXbvtoFy4RgBFruw/viewform"
          :onload="questionnaireOnload()"
          height="2100px"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          載入中…
        </iframe>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import PageContainer from '@/components/common/PageContainer.vue'
import Loading from '@/components/common/Loading.vue'

const META_TITLE = '伴心理諮商所 - 教師方案預約'
const META_DESCRIPTION =
  '諮商服務我們採全預約制，填寫表單後，我們需要1至3天的工作時程進行安排，請確認可接受等候再預約喔！'

export default {
  name: 'TeacherAppointment',
  components: {
    PageContainer,
    Loading
  },
  data() {
    return {
      isQuestionnaireLoading: true
    }
  },
  metaInfo() {
    return {
      title: META_TITLE,
      meta: [
        // 如果母畫面和子畫面都有 meta info，則兩個 description 都會併存，可以利用 vmid 去解決這個問題
        // 同樣的 vmid 子畫面會取代母畫面
        { vmid: 'description', name: 'description', content: META_DESCRIPTION },
        { property: 'og:title', content: META_TITLE },
        { property: 'og:site_name', content: META_TITLE },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: META_DESCRIPTION
        }
      ]
    }
  },
  methods: {
    questionnaireOnload() {
      this.isQuestionnaireLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.appointment {
  .list-style-type {
    list-style-type: disc;
  }

  .appointment__content {
    display: flex;
    justify-content: center;

    @include ipad {
      flex-direction: column;
    }
  }

  .loading {
    background-color: transparent;
  }

  .questionnaire {
    width: 675px;
    text-align: start;

    @include ipad {
      width: 100%;
    }
  }
}
</style>
