<template>
  <div class="banner">
    <!-- <Loading v-if="isBannerLoading" class="loading" /> -->
    <Slider v-if="!isBannerLoading" class="slider" :list="images" />
  </div>
</template>

<script>
// import Loading from '@/components/common/Loading.vue'
import Slider from '@/components/common/Slider'

import firebaseAPI from '@/api/firebaseAPI'

import { ImageData } from '@/components/admin/ImageGroup/imageConfig'

export default {
  name: 'Banner',
  components: {
    // Loading,
    Slider
  },
  data() {
    return {
      isBannerLoading: true,
      images: []
    }
  },
  computed: {},
  created() {
    this.getBannerImages()
  },
  methods: {
    async getBannerImages() {
      this.isBannerLoading = true
      const banners = await firebaseAPI.getBanners()
      this.images = banners.map(({ images, text, link, color }) => {
        return new ImageData(images, text, link, color)
      })

      this.isBannerLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  // height: 360px;
  .loading {
    background-color: transparent;
  }

  .slider {
    max-height: 360px;
  }
}
</style>
