<template>
  <div class="decorate-wrapper">
    <img class="anim-image" :src="Leaf" />
    <slot />
  </div>
</template>

<script>
import Leaf from '@/assets/images/decorate/leaf.png'
export default {
  name: 'DecorateWrapper',
  data() {
    return {
      Leaf
    }
  }
}
</script>

<style lang="scss" scoped>
.decorate-wrapper {
  position: relative;

  &:hover {
    .anim-image {
      opacity: 1;
      animation: rotationAnim infinite 2s;
    }
  }
  .anim-image {
    position: absolute;
    top: 0;
    right: -16px;
    width: 35px;
    transform-origin: center bottom;
    transform: rotate(0);
    opacity: 0;
  }

  @keyframes rotationAnim {
    50% {
      transform: rotate(60deg);
    }
    100% {
      transform: rotate(0);
    }
  }
}
</style>
