<template>
  <PageContainer class="space">
    <template #title>諮商空間</template>
    <template #content>
      <div class="space__content">
        <div class="image-list">
          <img
            v-for="(image, index) in Images"
            :key="`space_${index}`"
            class="image"
            :class="{
              // anim: isTurn(index)
            }"
            :src="image"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import PageContainer from '@/components/common/PageContainer.vue'
import { Images } from './spaceConfig.js'

const META_TITLE = '伴心理諮商所 - 諮商空間'
const META_DESCRIPTION = ''

export default {
  name: 'Space',
  components: {
    PageContainer
  },
  metaInfo() {
    return {
      title: META_TITLE,
      meta: [
        // 如果母畫面和子畫面都有 meta info，則兩個 description 都會併存，可以利用 vmid 去解決這個問題
        // 同樣的 vmid 子畫面會取代母畫面
        { vmid: 'description', name: 'description', content: META_DESCRIPTION },
        { property: 'og:title', content: META_TITLE },
        { property: 'og:site_name', content: META_TITLE },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: META_DESCRIPTION
        }
      ]
    }
  },
  data() {
    return {
      Images,
      animFinish: [],
      timeOut: null,
      animCount: 0
    }
  },
  methods: {
    isTurn(index) {
      return this.animFinish[index] || false
    },
    runImagesAnimation() {
      this.interval = setInterval(() => {
        this.$set(this.animFinish, this.animCount, true)

        if (this.animCount === Images.length - 1) {
          clearInterval(this.interval)
          console.log('end')
        }
        this.animCount++
      }, 500)
    }
  },
  mounted() {
    this.runImagesAnimation()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
.space {
  .space__content {
    margin: 0 auto;
    padding: 0 24px 36px;
  }

  .image-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;

    @include ipad {
      flex-direction: column;
      align-items: center;
    }
  }

  .image {
    width: calc(33% - 20px);
    // transform: translateY(10px);
    // opacity: 0;

    &.anim {
      animation: fadeIn 0.8s ease-in-out forwards;
    }

    @include ipad {
      width: 95%;
    }
  }

  @keyframes fadeIn {
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
</style>
