<template>
  <div class="header-logo" @click="onLogo">
    <img class="logo" :src="Logo" />
    <h1 class="title">伴心理諮商所</h1>
  </div>
</template>

<script>
import Logo from '@/assets/images/logo/logo.png'
export default {
  name: 'HeaderLogo',
  data() {
    return {
      Logo
    }
  },
  methods: {
    onLogo() {
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header-logo {
  display: flex;
  align-items: center;
  cursor: pointer;

  > * + * {
    margin-left: 12px;
  }

  .logo {
    width: 60px;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3px;
  }
}
</style>
