<template>
  <div class="rent-space"></div>
</template>

<script>
export default {
  name: 'RentSpace'
}
</script>

<style scoped>
.rent-space {
}
</style>
