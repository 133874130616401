<template>
  <Icon width="64" height="64" viewBox="0 0 64 64">
    <g id="_x32_5_attachment" />
    <g id="_x32_4_office" />
    <g id="_x32_3_pin" />
    <g id="_x32_2_business_card" />
    <g id="_x32_1_form" />
    <g id="_x32_0_headset" />
    <g id="_x31_9_video_call" />
    <g id="_x31_8_letter_box" />
    <g id="_x31_7_papperplane" />
    <g id="_x31_6_laptop" />
    <g id="_x31_5_connection" />
    <g id="_x31_4_phonebook" />
    <g id="_x31_3_classic_telephone" />
    <g id="_x31_2_sending_mail" />
    <g id="_x31_1_man_talking" />
    <g id="_x31_0_date" />
    <g id="_x30_9_review" />
    <g id="_x30_8_email" />
    <g id="_x30_7_information" />
    <g id="_x30_6_phone_talking" />
    <g id="_x30_5_women_talking" />
    <g id="_x30_4_calling" />
    <g id="_x30_3_women" />
    <g id="_x30_2_writing">
      <g>
        <g>
          <path
            d="M11.8418,20.4248l29.042-0.0034c0.5522,0,1-0.4478,1-1s-0.4478-1-1-1l-29.042,0.0034c-0.5522,0-1,0.4478-1,1     S11.2896,20.4248,11.8418,20.4248z"
          />
          <path
            d="M33.7593,25.8887H11.8418c-0.5522,0-1,0.4478-1,1s0.4478,1,1,1h21.9175c0.5522,0,1-0.4478,1-1     S34.3115,25.8887,33.7593,25.8887z"
          />
          <path
            d="M33.7593,33.1934H11.8418c-0.5522,0-1,0.4478-1,1s0.4478,1,1,1h21.9175c0.5522,0,1-0.4478,1-1     S34.3115,33.1934,33.7593,33.1934z"
          />
          <path
            d="M33.7763,40.5015H11.8418c-0.5522,0-1,0.4478-1,1s0.4478,1,1,1h21.9345c0.5522,0,1-0.4478,1-1     S34.3286,40.5015,33.7763,40.5015z"
          />
          <path
            d="M33.396,48.6914h-0.0005l-8.3828,0.0034c-0.5522,0-0.9995,0.4482-0.9995,1.0005s0.4478,0.9995,1,0.9995h0.0005     l8.3828-0.0034c0.5522,0,0.9995-0.4482,0.9995-1.0005S33.9482,48.6914,33.396,48.6914z"
          />
          <path
            d="M61.3525,12.0815l-2.2285-1.209c-0.7393-0.4023-1.5928-0.4932-2.4019-0.2515c-0.8101,0.2393-1.4771,0.7778-1.8809,1.519     l-4.3599,8.0269V5.8838c0-2.2632-1.8413-4.1045-4.1045-4.1045H9.5327c-0.2462,0-0.5461,0.1156-0.7222,0.291L1.2985,9.5375     C1.1246,9.7224,1,9.9928,1,10.25v47.8662c0,2.2632,1.8413,4.1045,4.1045,4.1045H46.377c2.2632,0,4.1045-1.8413,4.1045-4.1045     v-19.41l12.1372-22.3444C63.4482,14.834,62.8809,12.9141,61.3525,12.0815z M8.5156,5.1962v1.9493     c0,1.1606-0.9438,2.1045-2.1045,2.1045H4.4283L8.5156,5.1962z M48.4814,58.1162c0,1.1606-0.9438,2.1045-2.1045,2.1045H5.1045     C3.9438,60.2207,3,59.2769,3,58.1162V11.25h3.4111c2.2632,0,4.1045-1.8413,4.1045-4.1045V3.7793H46.377     c1.1606,0,2.1045,0.9438,2.1045,2.1045v17.9654L38.6118,42.02c-0.0438,0.0808-0.1229,0.3453-0.1211,0.4893l0.0864,7.3013     c0.0044,0.3682,0.2109,0.7046,0.5371,0.875c0.1455,0.0757,0.3047,0.1133,0.4629,0.1133c0.1968,0,0.3931-0.0581,0.562-0.1729     l5.9287-4.0278c0.0111-0.0076,0.0159-0.0214,0.0267-0.0294c0.1144-0.0842,0.2173-0.1868,0.2897-0.3197     c0,0,0.0001-0.0005,0.0002-0.0007c0.0001-0.0001,0.0002-0.0002,0.0002-0.0002l2.0967-3.86V58.1162z M40.5108,44.1913     l3.0764,1.6741l-3.032,2.0599L40.5108,44.1913z M60.8613,15.4067L45.1049,44.4142l-4.2584-2.3174L55.9409,14.313l0.6582-1.2197     c0.3062-0.5615,1.0093-0.769,1.5693-0.4639l2.2285,1.209C60.957,14.1436,61.165,14.8477,60.8613,15.4067z"
          />
        </g>
      </g>
    </g>
    <g id="_x30_1_chatting" />
  </Icon>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
  name: 'IconWriteForm',
  components: {
    Icon
  }
}
</script>