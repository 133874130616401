<template>
  <PageContainer class="fee">
    <template #content>
      <div class="fee__content">
        <div class="fee__item">
          <p class="item__title">諮商費用</p>
          <ul class="item__content ul-style--circle">
            <li>個別諮商：1500－2000元 / 50分鐘</li>
            <li>團體、工作坊：視當次活動主題、時間安排，將另行公告。</li>
          </ul>
        </div>
        <div class="fee__item">
          <p class="item__title">繳費方式</p>
          <ul class="item__content ul-style--circle">
            <li>
              於當次諮商後進行繳費，繳費方式僅接受現金與銀行轉帳，無提供刷卡服務。
            </li>
          </ul>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import PageContainer from '@/components/common/PageContainer.vue'

export default {
  name: 'Fee',
  components: {
    PageContainer
  }
}
</script>

<style lang="scss" scoped>
.fee {
  .fee__content {
    margin: 0 auto;
    padding: 0 24px 36px;

    > * + * {
      margin-top: 36px;
    }
  }

  .fee__item {
    display: flex;
    align-items: center;
    border: 1px solid $skin;
    border-radius: 8px;

    @include ipad {
      flex-direction: column;
    }
  }
  .ul-style--circle {
    padding-left: 20px;
    list-style-type: circle;
  }

  .item__title {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
  }

  .item__content {
    padding: 20px 20px 20px 30px;
    height: 100%;
    background: $skin;
    flex: 1;
  }
}
</style>
