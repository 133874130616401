<template>
  <div class="slider-wrap">
    <div class="slider" ref="slider">
      <div
        class="slider__list"
        :class="classes"
        ref="list"
        @touchstart="touchStart"
        @touchmove.prevent="touchMove"
        @touchend="touchEnd"
      >
        <div
          v-for="(item, index) in recomposeList"
          class="slider__item"
          :class="{ link: item.link }"
          :key="`slider_${index}`"
          ref="items"
          @click="onItem(item)"
        >
          <img class="item-image" :src="imageSrc(item)" />
          <div
            class="item-text"
            :style="{
              color: item.color || 'inherit'
            }"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <template v-if="!isOnlyOne">
      <div class="slider__pre btn" @click="onPre"><span>＜</span></div>
      <div class="slider__next btn" @click="onNext"><span>＞</span></div>
    </template>
    <div class="dots">
      <div v-for="(item, index) in recomposeList" :key="`dot_${index}`"></div>
    </div>
  </div>
</template>

<script>
import { IMAGE_TYPE } from '@/components/admin/ImageGroup/imageConfig.js'

const ANIMATION_TYPE = {
  SLIDER: 'slider',
  OPACITY: 'opacity'
}

const DIRECTION = {
  RIGHT: 'right',
  LEFT: 'left'
}

export default {
  name: 'Slider',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ANIMATION_TYPE.SLIDER
    },
    autoShiftSec: {
      type: Number,
      default: 6
    },
    isAutoShift: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentIndex: 0,
      isShifting: false,
      interval: null,

      threshold: 0,
      touchStartPosX: 0,
      touchEndPosX: 0
    }
  },
  computed: {
    classes() {
      return {
        shifting: this.isShifting
      }
    },
    recomposeList() {
      if (!this.list.length) {
        return []
      }

      if (this.isSlider) {
        // 補上最左邊和最右邊的資料(做無窮 slider 動畫用)
        return [this.list[this.list.length - 1], ...this.list, this.list[0]]
      }
      return this.list
    },
    isSlider() {
      return this.type === ANIMATION_TYPE.SLIDER
    },
    isFirst() {
      return this.currentIndex === 0
    },
    isLast() {
      return this.currentIndex === this.recomposeList.length - 1
    },
    isOnlyOne() {
      return this.list.length === 1
    },
    lastIndex() {
      if (this.isSlider) {
        return this.recomposeList.length - 2
      } else {
        return this.recomposeList.length - 1
      }
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    init() {
      if (this.isSlider) {
        this.initSlider()
      }
    },
    initSlider() {
      this.currentIndex = 1
      const sliderWidth = this.$refs.slider.clientWidth
      const listRef = this.$refs.list
      listRef.style.left = `-${sliderWidth}px`

      listRef.addEventListener('transitionend', this.transitionend)
      window.addEventListener('resize', this.resize)

      if (this.isAutoShift) {
        this.startAutoSlider()
      }
    },
    startAutoSlider() {
      if (this.isOnlyOne) {
        return
      }
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        this.sliderShift(DIRECTION.RIGHT)
      }, this.autoShiftSec * 1000)
    },
    stopAutoSlider() {
      clearInterval(this.interval)
    },
    sliderShift(direction) {
      if (this.isShifting) {
        return
      }
      this.isShifting = true

      if (direction === DIRECTION.RIGHT) {
        this.currentIndex += 1
      } else {
        this.currentIndex -= 1
      }

      this.shift()
    },
    shift() {
      const sliderWidth = this.$refs.slider.clientWidth
      const listRef = this.$refs.list
      listRef.style.left = `${this.currentIndex * -sliderWidth}px`
    },
    transitionend() {
      this.isShifting = false

      if (this.isFirst) {
        this.currentIndex = this.lastIndex
      } else if (this.isLast) {
        this.currentIndex = 1
      }

      this.shift()
    },
    touchStart(e) {
      this.threshold = this.$refs.slider.clientWidth / 4
      this.touchStartPosX = e.touches[0].clientX
      this.stopAutoSlider()
    },
    touchMove(e) {
      this.touchEndPosX = e.touches[0].clientX
      const moveDistance = this.touchEndPosX - this.touchStartPosX
      const sliderWidth = this.$refs.slider.clientWidth
      this.$refs.list.style.left = `${
        this.currentIndex * -sliderWidth + moveDistance
      }px`
    },
    touchEnd() {
      const moveDistance = this.touchEndPosX - this.touchStartPosX
      if (Math.abs(moveDistance) < this.threshold) {
        this.isShifting = true
        this.shift()
        return
      }

      if (moveDistance > 0) {
        this.onPre()
      } else {
        this.onNext()
      }
    },
    resize() {
      this.shift()
    },
    imageSrc(image) {
      if (this.isIpad) {
        return image.images[IMAGE_TYPE.SD].src
      }

      return image.images[IMAGE_TYPE.HDR].src
    },
    onPre() {
      this.sliderShift(DIRECTION.LEFT)
      this.stopAutoSlider()

      if (this.isAutoShift) {
        this.startAutoSlider()
      }
    },
    onNext() {
      this.sliderShift(DIRECTION.RIGHT)
      this.stopAutoSlider()

      if (this.isAutoShift) {
        this.startAutoSlider()
      }
    },
    onItem({ link }) {
      if (!link) {
        return
      }

      window.open(link, '_blank', 'noopener')
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-wrap {
  position: relative;
  opacity: 0;
  animation: opacityAnim ease-in-out 1s forwards;

  @keyframes opacityAnim {
    100% {
      opacity: 1;
    }
  }

  .slider {
    height: inherit;
    max-height: inherit;
    overflow: hidden;
  }

  .slider__list {
    position: relative;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: inherit;
  }

  .shifting {
    transition: left 0.5s;
  }

  .slider__item {
    position: relative;
    flex: none;
    width: 100%;
    height: inherit;
    max-height: inherit;
  }

  .item-image {
    width: 100%;
    height: inherit;
    max-height: inherit;
  }

  .item-text {
    position: absolute;
    top: 50%;
    right: 20%;
    font-size: 36px;
    font-weight: 700;
    transform: translateY(-50%);
    letter-spacing: 2px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);

    @include desktop {
      font-size: 30px;
    }

    @include ipad {
      font-size: 24px;
      right: 15%;
    }

    @include mobile {
      font-size: 16px;
      right: 10%;
    }
  }

  .slider__pre {
    position: absolute;
    top: calc(50% - 15px);
    left: 10px;
  }

  .slider__next {
    position: absolute;
    top: calc(50% - 15px);
    right: 10px;
  }

  .link {
    cursor: pointer;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: $white;
    border-radius: 50%;
  }
}
</style>
