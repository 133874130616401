import SpaceImage1 from '@/assets/images/space/space-1.jpg'
import SpaceImage2 from '@/assets/images/space/space-2.jpg'
import SpaceImage3 from '@/assets/images/space/space-3.jpg'
import SpaceImage4 from '@/assets/images/space/space-4.jpg'
import SpaceImage5 from '@/assets/images/space/space-5.jpg'
import SpaceImage6 from '@/assets/images/space/space-6.jpg'
import SpaceImage7 from '@/assets/images/space/space-7.jpg'
import SpaceImage8 from '@/assets/images/space/space-8.jpg'

const Images = [
  SpaceImage1,
  SpaceImage2,
  SpaceImage3,
  SpaceImage4,
  SpaceImage5,
  SpaceImage6,
  SpaceImage7,
  SpaceImage8
]

export { Images }
