const FOOTER_LIST = [
  {
    key: 'GROUNP_1',
    title: '伴心理諮商所',
    list: [
      {
        key: 'GROUNP_1_LIST_1',
        content: '宜蘭縣合法立案心理諮商所'
      },
      {
        key: 'GROUNP_1_LIST_2',
        content: '宜府心理字第ＸＹ34050033號'
      },
      {
        key: 'GROUNP_1_LIST_3',
        content: '各項服務採預約制',
        contentColor: 'brown'
      }
    ]
  },
  {
    key: 'GROUNP_2',
    title: '',
    list: [
      {
        key: 'GROUNP_2_LIST_1',
        title: '地址',
        content: '262宜蘭縣礁溪鄉仁愛路11巷5號',
        link: 'https://goo.gl/maps/s1R9cKbky6uncL8w8'
      },
      {
        key: 'GROUNP_2_LIST_2',
        title: 'Mail',
        content: 'accompany115@gmail.com',
        link: 'mailto:accompany115@gmail.com'
      },
      {
        key: 'GROUNP_2_LIST_3',
        title: '電話',
        content: '0975-087-710',
        link: 'TEL://0975-087-710'
      }
    ]
  },
  {
    key: 'GROUNP_3',
    title: '',
    list: [
      {
        key: 'GROUNP_3_LIST_1',
        title: '合作夥伴',
        content: '立心理諮商所',
        link: 'https://www.facebook.com/people/%E7%AB%8B%E5%BF%83%E7%90%86%E8%AB%AE%E5%95%86%E6%89%80-LIH-mental-health/100057346123763/'
      }
    ]
  }
]

export { FOOTER_LIST }
